/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-unescaped-entities */
import LayoutDefault from '@/components/layouts/Layout'
import IconSvg from '@/components/elements/IconSvg'
import ImgResponsive from '@/components/elements/ImgResponsive'
import TitleShadow from '@/components/modules/TitleShadow'
import PartnerBox from '@/components/modules/PartnerBox'
import SectionHeader from '@/components/modules/SectionHeader'
import SectionHomeAbout from '@/components/modules/SectionHomeAbout'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { MutableRefObject, useCallback, useRef } from 'react'
import SectionHomeAward from '@/components/modules/SectionHomeAward'
import useIsomorphicLayoutEffect from '@/utils/useIsomorphicEffect'
import JourneyAward from '@/components/modules/JourneyAward'
import WeFocus from '@/components/modules/WeFocus'
import MyCustomers from '@/components/modules/MyCustomers'

function Home() {
  const focusContainerRef = useRef<HTMLDivElement>(null)
  function useArrayRef<T>(): [MutableRefObject<T[]>, (ref: T) => void] {
    const ref = useRef<T[]>([])
    const setRef = useCallback((node: T) => {
      if (node) {
        ref.current.push(node)
      }
    }, [])
    return [ref, setRef]
  }

  gsap.registerPlugin(ScrollTrigger)

  useIsomorphicLayoutEffect(() => {
    const titleTl = gsap.timeline({
      scrollTrigger: {
        trigger: focusContainerRef.current,
        start: '-50px center',
        end: 'top center',
        // markers: true,
        // toggleActions: "restart pause reverse pause",
        // scrub: 1, // scrub is use set the amount of time to catch up, it kinda looks the scroll animation with ur scroller
      },
    })
    titleTl
      .addLabel('start')
      .from(focusContainerRef.current, { opacity: 0, translateX: '-100px' })
      .addLabel('entering')
      .to(focusContainerRef.current, { opacity: 1, translateX: '0px', duration: 0.5 })
      .addLabel('end')
    gsap.set('.focus-el-list', { opacity: 0, y: 20 })
    ScrollTrigger.batch('.focus-el-list', {
      start: '-160px center',
      end: 'top center',
      // markers: true,
      onEnter: (batch) => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.15 }),
    })
  }, [])

  return (
    <LayoutDefault
      preload={[
        {
          id: 1,
          href: '/images/home/home_top_banner.webp',
          imageSrcSet:
            '/images/home/home_top_banner-sm.webp 640w, /images/home/home_top_banner-md.webp 1024w, /images/home/home_top_banner.webp 1280w',
        },
      ]}
      background='white'
    >
      <main>
        <SectionHeader
          title={
            <>
              <IconSvg
                className='flex justify-center'
                svgClassName='w-10/12 mb-8 sm:mb-4 sm:w-4/12'
                path='/images/TWin.svg'
                title='twin'
              />
              <IconSvg
                className='flex justify-center'
                svgClassName='w-10/12 sm:w-4/12'
                path='/images/SYNERGY.svg'
                title='Synergy'
              />
            </>
          }
          className='homepage'
          bgColors='#1990FF'
          bgImage='/images/home/home_top_banner.webp'
          bgImageSm='/images/home/home_top_banner-sm.webp'
          bgImageMd='/images/home/home_top_banner-md.webp'
          bgImageWidth={1891}
          bgImageHeight={991}
          x={0}
          y={2.5}
          delay={0.5}
        />
        <SectionHomeAbout />
        <WeFocus />
        <SectionHomeAward />
        <div className='section-journey bg-primary py-24'>
          <div className='container mx-auto px-6' ref={focusContainerRef}>
            <div className='grid grid-cols-1 items-center gap-4 lg:grid-cols-2'>
              <div className=''>
                <ImgResponsive
                  className='move'
                  src='/images/home/mobile_app_home.webp'
                  alt='Journey'
                  width={774}
                  height={828}
                  loadingLazy
                />
              </div>
              <div className='text-white'>
                <span className='text-sm uppercase'>Masterpiece</span>
                <h3 className='mb-8 text-3xl xl:text-5xl'>ผลงาน</h3>
                <p className='mb-8 text-xl xl:text-2xl'>
                  ในปี 2015 เรามีผลงานที่ชื่อว่า "Kickdudes" ซึ่งเป็นแพลตฟอร์มสำหรับชวนเพื่อนเตะบอล
                  โดยมีแนวคิดที่ว่า
                  "คนที่ชอบกิจกรรมที่เหมือนกันสามารถมาร่วมกิจกรรมกันได้โดยไม่ต้องรู้จักกันก็ได้"
                </p>
                <p>
                  และฟุตบอลก็เป็นอีกหนึ่งกิจกรรมของกลุ่มผู้ชายที่ชอบฟุตบอลเหมือนกันและสามารถมาเล่นด้วยกันโดยที่ไม่รู้จักกันมาก่อนก็ได้
                  และมากกว่านั้น Kickdudes รวบรวมสนามไว้กว่า 300 สนาม
                  มีระบบการจองสนามให้กับนักฟุตบอลได้เลือก
                  และมีระบบบริหารสนามให้กับเจ้าของสนามได้ใช้ด้วย ซึ่งในตอนนั้น Kickdudes
                  ได้รับความสนใจอย่างมากทั้งในวงการสตาร์ทอัพและวงการฟุตบอล
                  ทำให้เราได้มีโอกาสร่วมงานกับหลาย ๆ บริษัทและหลาย ๆ หน่วยงาน เช่น สวทช กกท CMG
                  WARRIX Super Sport เป็นต้น
                </p>
              </div>
            </div>
          </div>
        </div>
        <JourneyAward />
        <MyCustomers />
        <div className='section-partner pt-24'>
          <div className='container mx-auto px-6 py-28'>
            <TitleShadow
              prologue='เพื่อนของเรา'
              colorPrologue='#EFEFEF'
              title='และนี่...เพื่อนของเรา'
              colorTitle='#000000'
              subtitle='My friends'
              colorSubtitle='#000000'
              align='center'
            />
          </div>
          <div
            className='focus-el grid w-full grid-cols-1 md:grid-cols-4'
            style={{
              borderTop: '1px solid #dbdbdb',
              background: '#FAFAFA',
            }}
          >
            <PartnerBox
              title='นวัตกรรมและเทคโนโลยี'
              imageMd='/images/partner/mitta.webp'
              imageLg='/images/partner/mitta.webp'
              width={150}
              height={150}
            />
            <PartnerBox
              title='ทีมงานที่มีความเชี่ยวชาญ'
              imageMd='/images/partner/rgb72.webp'
              imageLg='/images/partner/rgb72.webp'
              width={150}
              height={150}
            />
            <PartnerBox
              title='ความพึงพอใจของลูกค้า'
              imageMd='/images/partner/neumerlin.webp'
              imageLg='/images/partner/neumerlin.webp'
              width={150}
              height={150}
            />
            <PartnerBox
              title='ความพึงพอใจของลูกค้า'
              imageMd='/images/partner/cyberrex.webp'
              imageLg='/images/partner/cyberrex.webp'
              width={150}
              height={150}
            />
          </div>
        </div>
      </main>
    </LayoutDefault>
  )
}

export default Home
