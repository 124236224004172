/* eslint-disable react/no-unescaped-entities */
import React, { useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import TitleShadow from '../TitleShadow'
import useIsomorphicLayoutEffect from '@/utils/useIsomorphicEffect'

gsap.registerPlugin(ScrollTrigger)

const SectionHomeAward = () => {
  const aboutRef = useRef<HTMLParagraphElement>(null)
  const aboutTextRef = useRef<HTMLParagraphElement>(null)
  const aboutEndRef = useRef<HTMLDivElement>(null)

  useIsomorphicLayoutEffect(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: aboutRef.current,
        endTrigger: aboutEndRef.current,
        start: '-15% 0%',
        // end: 'top 35%',
        scrub: 1,
        // markers: true,
      },
    })
    timeline
      .fromTo(
        aboutTextRef.current,
        { filter: 'opacity(100%)' },
        {
          duration: 1.45,
          ease: 'slow.inOut',
          filter: 'opacity(50%)',
          y: -7,
        },
        0.05,
      )
      .to(
        aboutTextRef.current,
        {
          duration: 0.6,
          ease: 'power2.inOut',
          opacity: 0,
          // y: 20,
          scale: 0.84,
        },
        0.3,
      )
  }, [])
  return (
    <>
      <div className='section-award pt-24' ref={aboutRef}>
        <div className='award-container container mx-auto px-6 py-32'>
          <div className='grid grid-cols-1 gap-4 lg:grid-cols-2' ref={aboutTextRef}>
            <div className='award-title-wrap md:pr-0 lg:pr-28'>
              <TitleShadow
                prologue='รางวัล'
                colorPrologue='#EFEFEF'
                title='ประสบการณ์ - รางวัล'
                colorTitle='#1990FF'
                subtitle='My journey'
                colorSubtitle='#000000'
              />
            </div>
            <div className='award-content'>
              <h3 className='award-content-title mb-4 text-3xl'>เรารักสตาร์ทอัพ</h3>
              <p>
                เรารักและชื่นชอบในธุรกิจสตาร์ทอัพมาก
                เรามีความหลงใหลในการสร้างสรรค์ผลงานที่มาจากไอเดียที่ไม่เหมือนใครของเราให้เกิดขึ้นจริง
                และมีความสุขจากการมีผู้ใช้งานจริงและมีผู้ชื่นชอบในผลงานของเรา
                เราเติบโตมาจากการเป็นสตาร์ทอัพ เราคือทีมพัฒนาเล็ก ๆ
                ที่มุ่งหวังในการเติบโตอย่างมีสมรรถภาพและที่สำคัญเราไม่เคยหยุดที่จะเรียนรู้และหาประสบการณ์ใหม่
                ๆ ให้กับชีวิต
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='section-award-pin' ref={aboutEndRef}></div>
    </>
  )
}

export default SectionHomeAward
